/** window.REDs.stateConfig
 * Allows configuration of index.html's various states.
 * - 'route' property indicates the route represented in the address bar.
 * - 'namespace' property indicates a unique class to be applied to each section. Classes invoke CSS manipulations in _states.scss.
 * - 'parentSelector' property is a CSS selector, called with document.querySelector, which will serve as the state's DOM.
 * - 'eventMap' provides functions which are called when StateManager fires certain events relevant to this state.
 * - 'handlers' are bound to elements in the given state's code. Scoped to parentSelector's first matching node by default, but prepending 'body ' to a handler will make it global.
 */

window.REDs.stateConfig.push({
	namespace: 'load',
	route: null,
	parentSelector: 'section.loader',
	eventMap: {
		'on': function(parent, stateInstance, stateManager) {
			const loadingFill = parent.querySelector('.loadingFill');
			const loadedPercentSpan = parent.querySelector('.loadedPercent');
			const loader = stateManager.loader;
			let incrementTarget = 5;
			let demonstratedProgress = 0;
			let incrementProgressInterval;
			let isComplete = false;
			let targetState = 'mainVid';

			const incrementProgress = function() {
				if(demonstratedProgress < incrementTarget)
				{
					demonstratedProgress += 1;
					loadingFill.style.width = demonstratedProgress + '%';
					loadedPercentSpan.innerHTML = (demonstratedProgress < 10 ? '0' : '') + demonstratedProgress;
				}
				else
				{
					clearInterval(incrementProgressInterval);

					if(isComplete)
						stateManager.setState(targetState);
				}
			};

			loader.subscribeToProgress((progress) => {
				const isActiveState = stateManager.getState().current.namespace === stateInstance.namespace;

				if(!isActiveState)
					return;

				let progressAsInt = Math.floor(progress * 100);
				incrementTarget = Math.min(progressAsInt, 100);

				clearInterval(incrementProgressInterval);
				incrementProgressInterval = setInterval(incrementProgress, 30);
			});

			loader.setCompletionCallback((state) => {
				isComplete = true;
				targetState = state;

				return false;
			})
		},
		'afteroff': function(parent, stateInstance, stateManager) {
			const loadingFill = parent.querySelector('.loadingFill');

			loadingFill.style.width = '0%';
		},
	},
	handlers: {
		'.loadingGraphic': {
			'click': function(e, parent, stateInstance, stateManager) {

			}
		}
	}
});