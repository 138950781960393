window.REDs.initialize = function(stateManager, loader, router) {

	// Show page content
	const pageWrap = document.getElementById('pageIndex');
	pageWrap.style.display = "";

	// Scroll to get the address bar out of the way on mobile
	window.scrollTo(0,1);

	// Detect touch so we can distinguish between (most) desktops and tablets
	document.body.addEventListener('touchstart', () => {
		REDs.utils.addClass(document.body, 'hasTouch');
	});

	// Make sure to pause background video when not in view
	const homeWrap = pageWrap.querySelector('section.home');
	const scrollController = new REDs.ScrollController(homeWrap);
	const homeBgVideo = document.getElementById('homeBgVideo');
	scrollController.registerNodeActions(homeBgVideo, 'custom', {
		scrollbelowfold: function() {
			this.pause();
		},
		scrollabovefold: function() {
			this.play();
		},
	});

	// Bind menu button
	const menuTrigger = document.getElementById('menuTrigger');
	menuTrigger.addEventListener('click', () => {
		stateManager.setState('menu');
	});

	// Bind menu logo
	const menuHomeLink = document.getElementById('menuHomeLink');
	menuHomeLink.addEventListener('click', () => {
		stateManager.setState('homeJourney');
	});

	// Bind social button
	const socialButton = document.getElementById('socialButton');
	socialButton.addEventListener('click', () => {
		REDs.utils.toggleClass(socialButton, 'opened');
	});
};