window.REDs.FauxRouter = class {
	constructor(stateManager) {
		this._stateManager = stateManager;

		// Hijack links
		const allLinks = document.querySelectorAll('a[href]');

		for(let i = 0; i < allLinks.length; i++)
		{
			allLinks[i].setAttribute('hijacked', true);
			allLinks[i].addEventListener('click', this.hijackLinkClick.bind(this, allLinks[i]))
		}

		// Listen for back button press
		window.onpopstate = this.handlePopstate.bind(this);
	}

	handlePopstate(e) {
		this._stateManager.setState(this.hash);
	}

	get hash() {
		let hash = window.location.hash;

		if(hash)
			return hash.indexOf('#') >= 0 ? hash : '#' + hash;
		else
			return undefined;
	}

	set hash(hash) {
		let isValidString = hash && typeof hash === 'string';

		window.location.hash = isValidString ? this.uriComponentToHash(hash) : '';
	}

	hijackLinkClick(node, e) {

		let targetRoute = this.uriComponentToHash(node.href);

		// Sends all links with matching routes to their respective routes
		if(this._stateManager.queryStateByRoute(targetRoute))
		{
			e.preventDefault();

			this._stateManager.setState(targetRoute);
			REDs.utils.scrollToTop();
		}
	}

	uriComponentToHash(str) {
		let hashIndex = str.indexOf('#');

		return hashIndex >= 0 ? str.substr(hashIndex) : undefined;
	}
};

