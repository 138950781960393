document.addEventListener("DOMContentLoaded", function(event) {

	const pageWrapNode = document.getElementById('pageIndex');

	if(!pageWrapNode)
	{
		console.warn('#pageIndex not found, but index.js is included.');
		return;
	}

	// StateManager manages the current state. It also acts as a hub for other controllers
	const stateManager = new REDs.StateManager(pageWrapNode, 'load');

	// FauxRouter handles reading/writing URLs/hrefs/back clicks, etc.
	stateManager.router = new REDs.FauxRouter(stateManager);

	// Loader loads resources and broadcasts an event on completion. In this case, we're getting the provided hash from the URL to define our first state (after 'load', of course)
	stateManager.loader = new REDs.ResourceLoader(stateManager, stateManager.router.hash);

	window.REDs.initialize(stateManager);
});