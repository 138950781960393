window.REDs.StateInstance = class REDsStateInstance {
	constructor({namespace, route, parentSelector, eventMap, handlers, scrollTrack} = {namespace: '', route: '', parentSelector: 'body', eventMap: {}, handlers: {}, scrollTrack: false}) {
		if(!namespace)
			throw new Error('A new StateInstance requires a namespace');

		// Map unique values
		this._namespace = namespace;
		this._route = route;
		this._parentNode = document.querySelector(parentSelector);
		this._events = eventMap;
		this._handlers = handlers;
		this._data = {};
		this._activeHandlers = {};
		this._scrollTrack = !!scrollTrack;
	}

	get route() {
		return this._route;
	}

	get namespace() {
		return this._namespace;
	}

	set stateManager(parentStateManager) {
		this._stateManager = parentStateManager;
	}

	get stateManager() {
		return this._stateManager;
	}

	set scrollController(scrollController) {
		this._scrollController = scrollController;
	}

	get scrollController() {
		return this._scrollController;
	}

	getParent() {
		return this._parentNode;
	}

	setData(key, value) {
		this._data[key] = value;
	}

	getData(key) {
		return this._data[key];
	}

	isActive() {
		return this.stateManager.isCurrentState(this)
	}

	initializeScrollControl() {
		if(this._scrollTrack && !this.scrollController)
			this.scrollController = new REDs.ScrollController(this);
	}

	triggerEvent(event) {
		const matchedEvent = this._events[event];

		if(event === 'on')
		{
			// Bind handlers to applicable nodes
			this.bindAllHandlers();

			// Set up scroll control
			this.initializeScrollControl();
		}

		if(event.indexOf('off') >= 0)
		{
			// Unbind event handlers when any 'off' event is fired
			this.removeAllHandlers();

			// Clear data when 'off' event is fired
			this._data = {};

		}

		if(typeof matchedEvent === 'function')
			matchedEvent(this._parentNode, this, this._stateManager);
	}

	bindAllHandlers() {
		if(!this._handlers || !this._handlers instanceof Array)
			return;

		for(let selector in this._handlers)
		{
			if(this._handlers.hasOwnProperty(selector))
			{
				let thisSelector = this._handlers[selector];

				for(let event in thisSelector)
					if(thisSelector.hasOwnProperty(event))
						this.bindSingleHandler(selector, event, thisSelector[event]);
			}
		}
	}

	bindSingleHandler(selector, event, callback) {
		let matchingNodes;

		// Select matching elements relative to parent or to body (global)
		if(selector.indexOf('body') >= 0)
			matchingNodes = document.querySelectorAll(selector);
		else
			matchingNodes = this._parentNode.querySelectorAll(selector);

		if(!matchingNodes.length)
		{
			console.warn('No matching nodes for the selector "' + selector + '" while binding event handlers.');
			return;
		}

		const uniqueKey = '' + selector + "#" + event;

		// Remove handler if it already exists for some reason
		if(this._activeHandlers[uniqueKey])
			this.removeSingleHandler(this._activeHandlers[uniqueKey]);

		// Register a new handler
		this._activeHandlers[uniqueKey] = {
			nodes: matchingNodes,
			event: event,
			func: (e) => {
				callback.apply(e.target, [e, this._parentNode, this, this._stateManager]);
			}
		};

		for(let i = 0; i < matchingNodes.length; i++)
			matchingNodes[i].addEventListener(event, this._activeHandlers[uniqueKey].func);
	}

	removeAllHandlers() {

		// Send a 'leave' event to scroll controller
		if(this.scrollController)
			this.scrollController.triggerLeaveState();

		// Remove local handlers
		for(let uniqueKey in this._activeHandlers)
			if(this._activeHandlers.hasOwnProperty(uniqueKey))
				this.removeSingleHandler(this._activeHandlers[uniqueKey])
	}

	removeSingleHandler(activeHandler) {
		for(let i = 0; i < activeHandler.nodes.length; i++)
			activeHandler.nodes[i].removeEventListener(activeHandler.event, activeHandler.func);
	}
};