/** window.REDs.stateConfig
 * Allows configuration of index.html's various states.
 * - 'route' property indicates the route represented in the address bar.
 * - 'namespace' property indicates a unique class to be applied to each section. Classes invoke CSS manipulations in _states.scss.
 * - 'parentSelector' property is a CSS selector, called with document.querySelector, which will serve as the state's DOM.
 * - 'eventMap' provides functions which are called when StateManager fires certain events relevant to this state.
 * - 'handlers' are bound to elements in the given state's code. Scoped to parentSelector's first matching node by default, but prepending 'body ' to a handler will make it global.
 */

window.REDs.stateConfig.push({
	namespace: 'mainVid',
	route: '#documentary',
	parentSelector: 'section.videoPlayer',
	eventMap: {
		'on': function(parent, stateInstance, stateManager) {
			const vidNode = parent.querySelector('.mainVideo');
			const videoControls = parent.querySelector('.videoControls');
			const previousState = stateManager.getState().previous;
			const iosPlayButton = parent.querySelector('#iosPlayButton');
			const canPlay = !REDs.utils.isMobile() || !!(REDs.utils.isMobile() && stateInstance.getData('iosMainPlayTriggered'));

			let usuallyAddClass = canPlay ? 'addClass' : 'removeClass';
			let usuallyRemoveClass = canPlay ? 'removeClass' : 'addClass';

			if(!canPlay)
			{
				// Display play button on iOS because they require user interaction to play audio
				iosPlayButton.style.display = 'flex';
			}
			else {
				vidNode.play();
			}

			vidNode.muted = false;

			REDs.utils[usuallyAddClass](videoControls, 'playing');
			REDs.utils[usuallyRemoveClass](videoControls, 'paused');

			REDs.utils.addClass(videoControls, 'volumeOn');
			REDs.utils[usuallyRemoveClass](videoControls, 'volumeOff');

			if (previousState && previousState.namespace !== 'load')
				REDs.utils.addClass(parent, 'fromHomeSub');
		},
		'off': function(parent, stateInstance, stateManager) {
			const vidNode = parent.querySelector('.mainVideo');
			const videoControls = parent.querySelector('.videoControls');

			vidNode.pause();
			vidNode.currentTime = 0;
			vidNode.muted = false;

			REDs.utils.addClass(videoControls, 'paused');

			REDs.utils.removeClass(videoControls, 'playing');
			REDs.utils.addClass(videoControls, 'volumeOn');

			REDs.utils.removeClass(parent, 'fromHomeSub');
		}
	},
	handlers: {
		'.mainVideo': {
			'timeupdate': function(e, parent, stateInstance, stateManager) {
				const vidNode = this;
				const playedTimeNode = parent.querySelector('.textPlayedTime');
				const totalTimeNode = parent.querySelector('.textTotalTime');
				const playbackFillNode = parent.querySelector('.playbackFill');

				let currentSeconds = (vidNode.currentTime % 60).toFixed(0);
				currentSeconds = currentSeconds.length === 1 ? '0' + currentSeconds : currentSeconds;
				const currentMinutes = Math.floor(vidNode.currentTime / 60);
				const currentTimeString = currentMinutes + ':' + currentSeconds;

				let durationSeconds = (vidNode.duration % 60).toFixed(0);
				durationSeconds = durationSeconds.length === 1 ? '0' + durationSeconds : durationSeconds;
				const durationMinutes = Math.floor(vidNode.duration / 60);
				const durationString = durationMinutes + ':' + durationSeconds;

				const percentPlayed = (vidNode.currentTime / vidNode.duration * 100).toFixed(2) + '%';

				playedTimeNode.innerHTML = currentTimeString;
				totalTimeNode.innerHTML = durationString;
				playbackFillNode.style.width = percentPlayed;
			},
			'ended': function(e, parent, stateInstance, stateManager) {
				const state = stateManager.getState();
				let newStateNS = 'homeJourney';

				if(state.previous && state.previous.namespace !== 'load')
					newStateNS = state.previous.namespace;

				stateManager.setState(newStateNS);
			}
		},
		'.actionPlay': {
			'click': function(e, parent, stateInstance, stateManager) {
				const vidNode = parent.querySelector('.mainVideo');
				const videoControls = parent.querySelector('.videoControls');
				const iosPlayButton = parent.querySelector('#iosPlayButton');

				vidNode.play();

				REDs.utils.removeClass(videoControls, 'paused');
				REDs.utils.addClass(videoControls, 'playing');

				// Hide iOS play button and never show it again
				stateInstance.setData('iosMainPlayTriggered', true);
				iosPlayButton.style.display = 'none';
			}
		},
		'.actionPause': {
			'click': function(e, parent, stateInstance, stateManager) {
				const vidNode = parent.querySelector('.mainVideo');
				const videoControls = parent.querySelector('.videoControls');

				vidNode.pause();

				REDs.utils.removeClass(videoControls, 'playing');
				REDs.utils.addClass(videoControls, 'paused');
			}
		},
		'.actionMute': {
			'click': function(e, parent, stateInstance, stateManager) {
				const vidNode = parent.querySelector('.mainVideo');
				const videoControls = parent.querySelector('.videoControls');

				vidNode.muted = true;

				REDs.utils.removeClass(videoControls, 'volumeOn');
				REDs.utils.addClass(videoControls, 'volumeOff');
			}
		},
		'.actionUnmute': {
			'click': function(e, parent, stateInstance, stateManager) {
				const vidNode = parent.querySelector('.mainVideo');
				const videoControls = parent.querySelector('.videoControls');

				vidNode.muted = false;

				REDs.utils.removeClass(videoControls, 'volumeOff');
				REDs.utils.addClass(videoControls, 'volumeOn');
			}
		},
		'.closeButton': {
			'click': function(e, parent, stateInstance, stateManager) {
				stateManager.goBack();
			}
		}
	}
});