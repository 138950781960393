window.REDs.uiConfig = Object.freeze({
	homePlayback: {
		bgVideoJourney: {
			index: 1,
			vidStart: 2,
			vidEnd: 21,
		},
		bgVideoCar: {
			index: 2,
			vidStart: 50,
			vidEnd: 63,
		},
		bgVideoDesign: {
			index: 3,
			vidStart: 23,
			vidEnd: 47,
		},
	}
});