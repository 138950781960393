/** window.REDs.stateConfig
 * Allows configuration of index.html's various states.
 * - 'route' property indicates the route represented in the address bar.
 * - 'namespace' property indicates a unique class to be applied to each section. Classes invoke CSS manipulations in _states.scss.
 * - 'parentSelector' property is a CSS selector, called with document.querySelector, which will serve as the state's DOM.
 * - 'eventMap' provides functions which are called when StateManager fires certain events relevant to this state.
 * - 'handlers' are bound to elements in the given state's code. Scoped to parentSelector's first matching node by default, but prepending 'body ' to a handler will make it global.
 */

window.REDs.stateConfig.push({
	namespace: 'menu',
	route: '#menu',
	parentSelector: 'section.menu',
	eventMap: {
		'on': function(parent, stateInstance, stateManager) {

		},
		'off': function(parent, stateInstance, stateManager) {

		},
	},
	handlers: {
		'.menuCloseButton': {
			'click': function(e, parent, stateInstance, stateManager) {
				stateManager.goBack();
			}
		},
		'.centralContentWrap': {
			'touchend': function(e, parent, stateInstance, stateManager) {
				if(e.target.tagName.toLowerCase() === 'a')
					return;

				const contentWrap = parent.querySelector('.centralContentWrap');
				const chapterNavFill = parent.querySelector('.chapterNav .chapterIndicatorFill');

				const adjustedScroll = contentWrap.scrollLeft + contentWrap.offsetWidth/2;
				const position = Math.floor(adjustedScroll / contentWrap.offsetWidth);
				const scrollTarget = document.body.offsetWidth * position;

				REDs.utils.scrollTo(contentWrap, scrollTarget, 250, 'horo');

				chapterNavFill.style.left = position * 33.4 + '%';
			},
		},
	}
});