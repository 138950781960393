window.REDs.StateManager = class {
	constructor(parentNode, startingState){
		this._parentNode = parentNode;
		this._states = {};
		this._currentState = null;
		this._previousState = null;

		this.ingestStateConfig(REDs.stateConfig);

		this.setState(startingState);
	}

	setState(stateNamespaceOrRoute)
	{
		let matchingState = this.queryStateByNS(stateNamespaceOrRoute) || this.queryStateByRoute(stateNamespaceOrRoute);

		if(!matchingState || this.isCurrentState(matchingState))
			return;

		// Set new current/previous states
		this._previousState = this._currentState || null;
		this._currentState = matchingState;

		// Change classes based on current state
		this.changeParentClass(this._previousState, 'remove');
		this.changeParentClass(this._currentState, 'add');

		// Trigger events for state change
		this.triggerInstanceEvent(this._previousState, 'off');
		this.triggerInstanceEvent(this._currentState, 'on');
		this.triggerInstanceEvent(this._previousState, 'afteroff');

		// Set the hash matching the current state.
		if(this.router)
			this.router.hash = matchingState.route;
	}

	getState() {
		return {
			current: this._currentState,
			previous: this._previousState,
		}
	}

	goBack() {
		if(this._previousState)
			this.setState(this._previousState.namespace);
	}

	isCurrentState(...statesOrNamespaces) {
		if(!this._currentState)
			return false;

		for(let i = 0; i < statesOrNamespaces.length; i++)
		{
			let thisInstance = statesOrNamespaces[i];
			let namespace = thisInstance instanceof REDs.StateInstance ? thisInstance.namespace : thisInstance;

			if(namespace.toLowerCase() === this._currentState.namespace.toLowerCase())
				return true;
		}

		return false;
	}

	triggerInstanceEvent(stateInstance, event) {
		if(!stateInstance)
			return;

		let timeout = 0;

		switch(event)
		{
			case 'on':
				break;
			case 'off':
				break;
			case 'afteroff':
				timeout = 500;
				break;
			default:
				console.warn('Invalid event passed to stateManager#triggerInstanceEvent: ' + event)
				return;
		}

		setTimeout(stateInstance.triggerEvent.bind(stateInstance, event), timeout)

	}

	changeParentClass(stateInstance, addOrRemove = 'add') {
		if(!stateInstance)
			return;

		const method = addOrRemove === 'add' ? 'addClass' : 'removeClass';

		REDs.utils[method](this._parentNode, stateInstance.namespace);
	}

	queryStateByNS(stateNamespace) {
		const matchedState = this._states[stateNamespace];

		return matchedState || null;
	}

	queryStateByRoute(route) {
		let matchedState;

		for(let state in this._states)
		{
			if(this._states.hasOwnProperty(state) && this._states[state].route === route)
				matchedState = this._states[state];
		}

		return matchedState || null;
	}

	ingestStateConfig(stateConfigArray) {

		for(let i = 0; i < stateConfigArray.length; i++)
		{
			const thisConfig = stateConfigArray[i];
			let targetClass;

			if(this.queryStateByNS(thisConfig.namespace))
			{
				console.warn('Skipping duplicated state config for namespace: ' + thisConfig.namespace);
				continue;
			}

			switch(thisConfig.type)
			{
				case 'video':
					targetClass = 'VideoStateInstance';
					break;
				default:
					targetClass = 'StateInstance';
			}

			const stateInstance = new REDs[targetClass](stateConfigArray[i]);

			this.registerStateInstance(stateInstance);
		}
	}

	registerStateInstance(stateInstance) {
		const key = stateInstance.namespace;

		if(this._states[key])
			throw new Error('Duplicate keys cannot be registered in stateManager. Key: ' + key);

		this._states[key] = stateInstance;
		stateInstance.stateManager = this;
	}

	set loader(resourceLoaderInstance) {
		this._loader = resourceLoaderInstance;
	}

	get loader() {
		return this._loader;
	}

	set router(fauxRouterInstance) {
		this._router = fauxRouterInstance;
	}

	get router() {
		return this._router;
	}
};