window.REDs.windowEvents = new class {
	constructor() {
		let frameActive = false;

		this._subscriptions = [];

		// Request the scroll event with an animation frame.
		window.addEventListener('scroll', (e) => {
			if(!frameActive)
			{
				window.requestAnimationFrame(() => {
					this.emit('scroll');
					frameActive = false;
				});

				frameActive = true;
			}
		});

		// Request the scroll event with an animation frame.
		window.addEventListener('resize', (e) => {
			this.emit('resize');
		});
	}

	subscribe(type, callback) {
		if(typeof callback === 'function')
			this._subscriptions.push({type: type, callback: callback})
	}

	emit(type) {

		for(let i = 0; i < this._subscriptions.length; i++)
			if(this._subscriptions[i].type === type)
				this._subscriptions[i].callback();
	}
};