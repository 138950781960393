/** window.REDs.stateConfig
 * Allows configuration of index.html's various states.
 * - 'route' property indicates the route represented in the address bar.
 * - 'namespace' property indicates a unique class to be applied to each section. Classes invoke CSS manipulations in _states.scss.
 * - 'parentSelector' property is a CSS selector, called with document.querySelector, which will serve as the state's DOM.
 * - 'eventMap' provides functions which are called when StateManager fires certain events relevant to this state.
 * - 'handlers' are bound to elements in the given state's code. Scoped to parentSelector's first matching node by default, but prepending 'body ' to a handler will make it global.
 */

window.REDs.stateConfig.push({
	namespace: 'homeCar',
	route: '#home/car',
	parentSelector: 'section.home',
	eventMap: {
		'on': function(parent, stateInstance, stateManager) {
			// Scroll to this element's shapes/text (doesn't do anything on desktop)
			let contentWrap = parent.querySelector('.centralContentWrap');
			contentWrap.scrollLeft = document.body.offsetWidth * 1;

			REDs.utils.homeHandleVideoOn(REDs.uiConfig.homePlayback.bgVideoCar, stateInstance);
		},
		'off': function(parent, stateInstance, stateManager) {
			REDs.utils.homeHandleVideoOff(stateManager);
		},
	},
	handlers: {
		'.centralContentWrap': {
			'touchend': function (e, parent, stateInstance, stateManager) {
				REDs.utils.handleHomeScrollEnd(e, parent, stateInstance, stateManager);
			},
		},
		'body #homeBgVideo': {
			'timeupdate': function (e, parent, stateInstance, stateManager) {
				REDs.utils.homeHandleVideoTimeUpdate(this, stateInstance);
			},
		},
	}
});