window.REDs.ScrollController = class {
	constructor(stateInstance) {

		// The provided stateInstance can be either an actual instance of REDs.StateInstance or a parent node. this.validateStateInstance() will normalize either.

		this._stateInstance = this.validatedStateInstance(stateInstance);
		this._parentNode = this._stateInstance.getParent();
		this._scrollTop = 0;
		this._targets = [];
		this._repeatPlayTypes = ['parallax'];

		// Subscribe to global scroll event
		REDs.windowEvents.subscribe('scroll', () => {
			if(this._stateInstance.isActive())
				this.onScroll();
		});

		// Subscribe to global resize event
		REDs.windowEvents.subscribe('resize', () => {
			this.onResize();
		});

		// Register all applicable nodes with the .scrollTarget class
		this.getTargetNodes()
	}

	onScroll(e) {
		// Any item touching the middle 60% of viewport will qualify for 'incenter'
		const viewCenterSize = .60;

		const scrollTop = this.getScrollTop();
		const viewHeight = window.innerHeight;
		const scrollBottom = scrollTop + viewHeight;
		const viewCenterTop = scrollTop + viewHeight * (1-viewCenterSize) / 2;
		const viewCenterBottom = viewCenterTop + viewHeight * viewCenterSize;

		// Find all elements' positions
		for(let i = 0; i < this._targets.length; i++)
		{
			const target = this._targets[i];
			const targetNode = target.node;

			if(!targetNode)
				continue;

			// If this element's dimensions haven't been indexed, index them. If there aren't any dimensions, the element might not be visible. We'll exit and check again next loop.
			if(!(typeof target.height === 'number' || typeof target.top === 'number'))
				if(!this.requestTargetDimensions(target))
					continue;

			const targetTop = target.top;
			const targetHeight = target.height;
			const targetBottom = targetTop + targetHeight;

			const isInView = targetTop <= scrollBottom && targetBottom >= scrollTop;
			const isInCenter = targetTop <= viewCenterBottom && targetBottom >= viewCenterTop;

			const isBelowFold = scrollTop > viewHeight;

			// Trigger handlers based on detected event
			if(isInView && target.handlers.inview)
				this.triggerHandler(target, 'inview');
			if(!isInView && target.handlers.notinview)
				this.triggerHandler(target, 'notinview');
			if(isInCenter && target.handlers.incenter)
				this.triggerHandler(target, 'incenter');
			if(isBelowFold && target.handlers.scrollbelowfold)
				this.triggerHandler(target, 'scrollbelowfold');
			if(!isBelowFold && target.handlers.scrollabovefold)
				this.triggerHandler(target, 'scrollabovefold');
		}
	}

	onResize() {

		// Reset target sizes so that they can be updated on the next scroll loop
		for(let i = 0; i < this._targets.length; i++)
		{
			this._targets[i].height = undefined;
			this._targets[i].top = undefined;
		}
	}

	getTargetNodes() {
		let targetNodes = this._parentNode.querySelectorAll('.scrollTarget');

		for(let i = 0; i < targetNodes.length; i++)
		{
			let thisNode = targetNodes[i];

			if(thisNode.className.indexOf('scrollTargetVideo') >= 0)
				this.registerNodeActions(thisNode, 'loopVideo');
			if(thisNode.className.indexOf('scrollTargetFloatInText') >= 0)
				this.registerNodeActions(thisNode, 'titleFloatIn');
			if(thisNode.className.indexOf('scrollTargetBlockReveal') >= 0)
				this.registerNodeActions(thisNode, 'blockReveal');
			if(thisNode.className.indexOf('scrollTargetAnimatedClassInView') >= 0)
				this.registerNodeActions(thisNode, 'classInView');
			if(thisNode.className.indexOf('scrollTargetAnimatedClassInCenter') >= 0)
				this.registerNodeActions(thisNode, 'classInCenter');
		}
	}

	registerNodeActions(node, type, handlers, repeatPlay) {

		handlers = handlers || this.getStandardHandlers(type);

		if(type === 'titleFloatIn')
			this.convertStringToSpanArray(node);

		let targetObj = {
			node: node,
			handlers: handlers,
			repeatPlay: repeatPlay || this._repeatPlayTypes.indexOf(type) >= 0,
		};

		this.requestTargetDimensions(targetObj);

		this._targets.push(targetObj);
	}

	requestTargetDimensions(target) {

		// If this element is hidden for some reason, let's assume it's out of view and see if it re-appears.
		// By default elements shouldn't shrink their size in these animations. They should use the CSS transform property to transform
		if(!(target.node.offsetHeight || target.node.offsetWidth))
			return false;

		target.height = target.node.offsetHeight;
		target.top = this.getNodeTopPosition(target.node);

		return true;
	}

	convertStringToSpanArray(node) {
		let spans = node.getElementsByTagName('span');

		for(let i = spans.length-1; i >= 0 ; --i)
		{
			let thisTextArray = spans[i].innerHTML;
			let separatedText = '';

			for(let j = thisTextArray.length-1; j >= 0; j--)
			{
				let letter = thisTextArray[j];
				let isWhiteSpace = /\s+/.test(letter);

				separatedText = (isWhiteSpace ? ' ' : '<i>' + letter + '</i>') + separatedText;
			}

			// Preserve ampersands (sorry other entities!)
			separatedText = separatedText.replace(/&.+;/ig, '&amp;');

			spans[i].innerHTML = separatedText;
		}
	}

	getStandardHandlers(type) {
		let handlers = {};

		// Assign event handlers for each animation type. Types with only one handler will only fire once unless 'repeatPlay' is set on the target object
		switch(type)
		{
			case 'loopVideo':
				handlers.notinview = function() { this.pause(); };
				handlers.leavestate = handlers.notinview;
				handlers.inview = function() {
					if(this.className.indexOf('revealed'))
						this.play();
					else
						setTimeout(this.play, 250);
				};
				break;
			case 'titleFloatIn':
				handlers.incenter = function() { REDs.utils.addClass(this, 'floatTextIn'); };
				break;
			case 'blockReveal':
				handlers.inview = function() { REDs.utils.addClass(this, 'revealed'); };
				break;
			case 'classInView':
				handlers.inview = function() { REDs.utils.addClass(this, 'animated'); };
				break;
			case 'classInCenter':
				handlers.incenter = function() { REDs.utils.addClass(this, 'animated'); };
				break;
		}

		return handlers;
	}

	triggerHandler(target, event, ...args) {

		// Does the target have this event?
		if(!target.handlers[event])
			return;

		// Prevent repeats unless explicitly configured with repeatPlay
		if(target.previousEvent === event && !target.repeatPlay)
			return;

		target.handlers[event].apply(target.node, ...args);

		target.previousEvent = event;
	}

	triggerLeaveState() {
		for(let i = 0; i < this._targets.length; i++)
			this.triggerHandler(this._targets[i], 'leavestate')
	}

	getNodeTopPosition(node) {
		let posY = 0;

		while(node && node.tagName !== 'BODY') {
			posY += node.offsetTop;
			node = node.offsetParent;
		}

		return posY;
	}

	getScrollTop() {
		this._scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

		return this._scrollTop;
	}

	validatedStateInstance(stateInstance) {
		if (stateInstance instanceof REDs.StateInstance)
			return stateInstance;

		// If it's not an actual StateInstance, assume it's a node.
		return {
			isActive: () => { return true; },
			getParent: () => { return stateInstance; },
		};
	}
};