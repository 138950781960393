window.REDs.ResourceLoader = class {
	constructor(stateManager, targetState = 'mainVid') {
		this._stateManager = stateManager;
		this._assets = [];
		this._targetState = targetState;
		this._progress = 0;
		this._progressCallbacks = [];
		this._onComplete = null;

		this.registerAssets();
		this.assetLoadInterval();
	}

	registerAssets() {
		const assetItems = document.querySelectorAll('' +
			'video,' +
			'[data-preload-src]'
		);

		for(let i = 0; i < assetItems.length; i++)
		{
			const preloadSrc = assetItems[i].getAttribute('data-preload-src');

			if(assetItems[i] instanceof HTMLVideoElement)
			{
				const isPreloadFull = assetItems[i].getAttribute('data-preload-full');
				const type = isPreloadFull ? 'videoFull' : 'video';
				this.registerSingleAsset(assetItems[i], type);
			}
			else if(preloadSrc)
			{
				this.registerSingleAsset(preloadSrc, 'imgSrc');
			}
		}
	}

	registerSingleAsset(asset, type) {
		this._assets.push({
			target: asset,
			type: type,
			looping: type === 'video' && asset.loop,
			loaded: false,
		});

		if(asset instanceof Node && asset.poster)
			this.registerSingleAsset(asset.poster, 'imgSrc');
	}

	assetLoadInterval() {
		const maxIterations = 21;
		let iterationsLeft = maxIterations;
		const rate = 500;

		let loadAssets = () => {
			const percentComplete = this.validateAllAssets();
			const percentToQuit = (maxIterations - iterationsLeft) / maxIterations;

			if(percentComplete !== 1 && iterationsLeft > 0)
				setTimeout(loadAssets, rate);
			else
				this.resolveLoadSession();

			this.updateProgress(percentComplete, percentToQuit);

			iterationsLeft -= 1;
		};

		setTimeout(loadAssets, rate);
	}

	validateAllAssets() {
		let totalComplete = 0;

		for(let i = 0; i < this._assets.length; i++)
		{
			let thisAsset = this._assets[i];

			switch(thisAsset.type)
			{
				case 'video':
					thisAsset.loaded = !!this.validateSimpleVideoAsset(thisAsset);
					break;
				case 'videoFull':
					thisAsset.loaded = !!this.validateFullVideoAsset(thisAsset);
					break;
				case 'imgSrc':
					thisAsset.loaded = !!this.validateImgSrcAsset(thisAsset);
					break;
				default:
					console.warn('Invalid asset registered in ResourceLoader: ', thisAsset)
			}

			if(thisAsset.loaded)
				totalComplete += 1;
		}

		return totalComplete / this._assets.length;
	}

	validateFullVideoAsset(videoAsset) {
		if(videoAsset.loaded)
			return true;
		if(videoAsset.loading)
			return false;

		videoAsset.loading = true;

		// We're giving up on background videos in IE/Edge. They conflict with animations and other videos and object-fit isn't supported.
		if(REDs.utils.isExplorerOrEdge())
		{
			const reason = "Optimizing rendering capacity for this browser.";
			this.abortFullVideoLoad(videoAsset, reason);
			return true;
		}

		const videoSource = this.getVideoSourceFromDOM(videoAsset.target);

		if(videoSource)
			this.preloadFullVideo(videoAsset, videoSource);

		return false;
	}

	validateSimpleVideoAsset(videoAsset) {
		return videoAsset.target.readyState === 4;
	}

	validateImgSrcAsset(imgSrcAsset) {
		if(imgSrcAsset.loaded)
			return true;
		if(imgSrcAsset.image)
			return false;

		imgSrcAsset.image = new Image();

		imgSrcAsset.image.onload = () => {
			imgSrcAsset.loaded = true;
		};

		imgSrcAsset.image.src = imgSrcAsset.target;
	}

	preloadFullVideo(videoAsset, videoSource) {
		// 20 seconds until we give up and use a fallback image
		const giveUpDuration = 20 * 1000;

		// 2 seconds to try placing the source again if the video is currently playing
		const checkIfNotPlayingFreq = 2000;

		videoAsset.request = new XMLHttpRequest();
		let _this = this;

		videoAsset.request.open('GET', videoSource.url, true);
		videoAsset.request.responseType = 'blob';

		videoAsset.request.onload = function() {
			if (this.readyState === 4 && this.status === 200) {

				let videoUrl;
				videoAsset.loaded = true;

				const timeoutFunction = () => {
					const isSet = _this.setVideoAssetSrc(videoAsset.target, videoUrl);

					if(!isSet)
						setTimeout(timeoutFunction, checkIfNotPlayingFreq)
				};

				_this.sanitizeBlob(this.response, videoSource.mimetype, (blobUrl) => {
					videoUrl = blobUrl;
					timeoutFunction();
				});
			}
		};

		videoAsset.request.send();

		// Abort XMLHttpRequest in X time if it fails to load
		const abortReason = "A video download request exceeded the acceptable timeframe.";
		setTimeout(this.abortFullVideoLoad.bind(this, videoAsset, abortReason), giveUpDuration);
	}

	getVideoSourceFromDOM(videoNode) {
		let sourceNodes = videoNode.querySelectorAll('source');
		let videoSource = null;
		let potentiallyUnplayableSource = null;

		for(let i = sourceNodes.length-1; i >= 0; i--)
		{
			let thisSourceNode = sourceNodes[i];
			let videoType = thisSourceNode.getAttribute('type');
			let canPlayGivenType = videoNode.canPlayType(videoType);

			if(canPlayGivenType === 'maybe' || canPlayGivenType === 'probably')
			{
				videoSource = {
					url: thisSourceNode.getAttribute('src'),
					mimetype: videoType,
				};

				if(canPlayGivenType === 'probably')
					return videoSource;
			}

			if(!videoSource && videoType)
			{
				potentiallyUnplayableSource = {
					url: thisSourceNode.getAttribute('src'),
					mimetype: 'video/mp4',
				}
			}
		}

		if(!videoSource)
			console.warn('Unable to get source from a .video[data-preload-full] elem with a readable mimetype. Does it have the `type` attribute set? ', videoNode);

		return videoSource || potentiallyUnplayableSource;
	}

	sanitizeBlob(videoBlob, mimetype, callback) {
		let reader = new FileReader();

		reader.onloadend = function() {

			let byteCharacters = atob(reader.result.slice(reader.result.indexOf(',') + 1));
			let byteNumbers = new Array(byteCharacters.length);

			for(let i = 0; i < byteCharacters.length; i++)
				byteNumbers[i] = byteCharacters.charCodeAt(i);

			let byteArray = new Uint8Array(byteNumbers);
			let blob = new Blob([byteArray], {type: mimetype});
			let videoBlobUrl = URL.createObjectURL(blob);

			callback(videoBlobUrl);
		};

		reader.readAsDataURL(videoBlob);
	}

	setVideoAssetSrc(videoNode, videoUrl) {
		const videoPlaying = REDs.utils.videoIsPlaying(videoNode);

		if(videoPlaying)
			return false;

		videoNode.src = videoUrl;
		return true;
	}

	abortFullVideoLoad(videoAsset, consoleWarning) {
		if(videoAsset.loaded)
			return;

		// End download if there is one
		if(videoAsset.request)
		{
			videoAsset.request.abort();
			delete videoAsset.request;
		}

		// Stop the video from loading any more and force its poster image to display (assuming it has one).
		videoAsset.target.pause();

		let videoFallbackNode = document.querySelector('.videoFallbackSet[data-for="' + videoAsset.target.id + '"]');

		// If we have a .videoFallbackSet node with a `data-for` property set to the target video's ID, run with that.
		if(videoFallbackNode)
		{
			videoAsset.target.parentNode.removeChild(videoAsset.target);
			REDs.utils.addClass(document.body, 'bgVideoFallback_' + videoAsset.target.id + '_active');
			videoAsset.loaded = true;

			if(videoAsset.target.id === 'homeBgVideo')
				REDs.utils.useHomeBgVideoFallback();
		}


		console.warn('Aborting "fullVid" preloads.', consoleWarning || '');
	}

	abortLoopingVideo(videoAsset, consoleWarning) {
		// Return if this video doesn't have a poster image to fall back on.
		if(!videoAsset.target.poster)
			return;

		videoAsset.target.innerHTML = '';

		videoAsset.target.src = "";

		videoAsset.target.load();

		console.warn('Aborting looping video load.', consoleWarning || '');
	}

	abortAllFullLoads(consoleWarning) {
		for(let i = 0; i < this._assets.length; i++)
			if(this._assets[i].type === 'fullVid' && !this._assets[i].loaded)
				this.abortFullVideoLoad(this._assets[i], consoleWarning);
	}

	abortAllLoopingVideos(consoleWarning) {
		for(let i = 0; i < this._assets.length; i++)
			if(this._assets[i].type === 'video' && !this._assets[i].loaded && this._assets[i].looping)
				this.abortLoopingVideo(this._assets[i], consoleWarning);
	}

	getProgress() {
		return this._progress;
	}

	detectVerySlowLoad() {

		let mainVideo = document.querySelector('.mainVideo');
		let warningReason = 'Slow download detected.';

		// Bail if the main video isn't ready to play yet
		if(mainVideo.readyState < 4)
		{
			this.abortAllFullLoads(warningReason);
			this.abortAllLoopingVideos(warningReason);
		}
	}

	updateProgress(percentComplete, percentToQuit) {
		this._progress = Math.max(percentComplete, percentToQuit);

		this.onProgress();
	}

	subscribeToProgress(callback) {
		this._progressCallbacks.push(callback);
	}

	onProgress() {
		for(let i = 0; i < this._progressCallbacks.length; i++)
			if(typeof this._progressCallbacks[i] === 'function')
				this._progressCallbacks[i](this._progress);
	};

	setCompletionCallback(callback) {

		// Callback called when preloading is complete (one way or another). Callback can return `false` to prevent ResourceLoader's state change
		this._onComplete = callback;
	}

	resolveLoadSession() {

		// Cancel full video loads if important resource(s) didn't load
		this.detectVerySlowLoad();

		// If something is listening to the onComplete event, give them a chance to cancel
		if(typeof this._onComplete === 'function')
			if(this._onComplete(this._targetState) === false)
				return false;

		// Show me the site already!
		this._stateManager.setState(this._targetState);
	}
};